<template>
  <v-card flat>
    <h1>{{ d.name }}</h1>
    <v-data-table
      :items="turner"
      :headers="[
        { text: 'Turner', value: 'person', sortable: false },
        { text: 'Mannschaft', value: 'team.name', sortable: false },
        { text: 'D-Note', value: 'dscore', sortable: false },
        { text: 'E-Note', value: 'escore', sortable: false },
        { text: 'Penalty', value: 'penalty', sortable: false },
        { text: 'Endnote', value: 'final', sortable: false }
      ]"
      hide-default-footer
      :items-per-page="-1"
      :mobile-breakpoint="0"
      :item-class="(item) => ({ even: item.i % 2 === 0, odd: item.i % 2 === 1 })"
    >
      <template #item.person="{item}">
        <span class="person">
          {{ item.person | person }}
        </span>
      </template>
      <template #item.dscore="{item}">
        <template v-if="item.final !== null">
          {{ item.result.dscore | float1_0 }}
        </template>
      </template>
      <template #item.escore="{item}">
        <template v-if="item.final !== null">
          {{ item.result.escore | float2_0 }}
        </template>
      </template>
      <template #item.penalty="{item}">
        <template v-if="item.final !== null && item.penalty">
          {{ item.result.penalty | float1_0 }}
        </template>
      </template>
      <template #item.final="{item}">
        <span v-if="item.final !== null" class="final">
          {{ item.result.final | float2_0 }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { useCalc } from '@/views/components/stb_m_2021/plugins/calc'

export default {
  name: 'lf',

  setup (props, context) {
    return {
      ...useCalc(props, context)
    }
  },

  props: {
    es: {
      type: Object,
      required: true
    },
    rs: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    did: {
      type: String,
      required: true
    }
  },

  computed: {
    e () {
      return Object.values(this.es) || []
    },
    d () {
      return this.df.find(d => d._id === this.did)
    },
    mannschaften () {
      return this.e.reduce((acc, curr) => {
        acc.push(...this.mannschaftenangeraet(curr, this.df, this.did).map(m => ({ ...m, e: curr })))
        return acc
      }, []).sort((a, b) => a.df < b.df ? -1 : 1)
    },
    turner () {
      const t = this.mannschaften.reduce((acc, curr) => {
        acc.push(...this.turnerangeraet(curr.e, this.rs[curr.e._id], this.df, this.did, curr.team._id))
        return acc
      }, []).slice().reverse()

      const i = t.findIndex(t => t.result?.final !== null)
      if (i === -1) t.splice(0, t.length - 1)
      if (i > 0) t.splice(0, i - 1)

      return t.map((t, i) => ({ ...t, i }))
    }
  }
}
</script>

<style scoped>
.person {
  font-weight: bold;
  font-size: 120%;
}
.final {
  font-weight: bold;
  font-size: 150%;
}
</style>
