var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('h1',[_vm._v(_vm._s(_vm.d.name))]),_c('v-data-table',{attrs:{"items":_vm.turner,"headers":[
      { text: 'Turner', value: 'person', sortable: false },
      { text: 'Mannschaft', value: 'team.name', sortable: false },
      { text: 'D-Note', value: 'dscore', sortable: false },
      { text: 'E-Note', value: 'escore', sortable: false },
      { text: 'Penalty', value: 'penalty', sortable: false },
      { text: 'Endnote', value: 'final', sortable: false }
    ],"hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":0,"item-class":(item) => ({ even: item.i % 2 === 0, odd: item.i % 2 === 1 })},scopedSlots:_vm._u([{key:"item.person",fn:function({item}){return [_c('span',{staticClass:"person"},[_vm._v(" "+_vm._s(_vm._f("person")(item.person))+" ")])]}},{key:"item.dscore",fn:function({item}){return [(item.final !== null)?[_vm._v(" "+_vm._s(_vm._f("float1_0")(item.result.dscore))+" ")]:_vm._e()]}},{key:"item.escore",fn:function({item}){return [(item.final !== null)?[_vm._v(" "+_vm._s(_vm._f("float2_0")(item.result.escore))+" ")]:_vm._e()]}},{key:"item.penalty",fn:function({item}){return [(item.final !== null && item.penalty)?[_vm._v(" "+_vm._s(_vm._f("float1_0")(item.result.penalty))+" ")]:_vm._e()]}},{key:"item.final",fn:function({item}){return [(item.final !== null)?_c('span',{staticClass:"final"},[_vm._v(" "+_vm._s(_vm._f("float2_0")(item.result.final))+" ")]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }